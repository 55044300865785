import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";

const AsyncViewContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/View/HomeView"), loading: Loading });
const AsyncDefaultContainer = loadable({ loader: () => import("./containers/View/DefaultView"), loading:Loading})
const AsyncLandingContainer = loadable({ loader: () => import("./containers/View/LandingView"), loading: Loading })
const AsyncUnauthorizedContainer = loadable({ loader: () => import("./containers/View/UnauthorizedView"), loading: Loading })
const AsyncErrorContainer = loadable({ loader: () => import("./containers/View/ErrorView"), loading:Loading})
const AsyncPricingContainer = loadable({ loader: () => import("./containers/View/PricingView"), loading:Loading})
const AsyncIsntallContainer = loadable({ loader: () => import("./containers/View/InstallView"), loading:Loading})
const AsyncPrepContainer = loadable({ loader: () => import("./containers/View/PreparationView"), loading: Loading })

const Routes: React.FC = () => {

    return (
        <Switch>
            <Route exact path="/" render={() => <AsyncDefaultContainer /> } />
            <Route exact path="/install" render={() => <AsyncIsntallContainer /> } />
            <Route exact path="/prepare/" render={() => <AsyncPrepContainer /> } />
            <Route exact path="/landing" render={() => <AsyncLandingContainer />} />
            <Route exact path="/unauthorized" render={() => <AsyncUnauthorizedContainer />} />
            <Route exact path="/error" render={() => <AsyncErrorContainer /> } />
            <Route exact path="/pricing" render={() => <AsyncPricingContainer />} />
            <Route exact path="/:objectType/:objectId/:portalId/:userId" render={() => <AsyncViewContainer />} />
        </Switch>
    );
}

export default Routes;